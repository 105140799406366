/**
 * This file is automatically generated. DO NOT manually edit it. For changes see
 * "yarn run code-gen" and the "gatsby-code-gen.js" script.
 */
// prettier-ignore
import * as React from 'react';
import {Router} from '@reach/router';
import Layout from '@app/components/layout';
import LangPage from '@app/lang-pages/lang-page';
import CategoryPage from '@app/lang-pages/category-page';
import ArticlePage from '@app/lang-pages/article-page';
import theme from '@app/theme';
import WelcomePage from '@app/lang-pages/welcome-page';
import YourTodoList from '@app/lang-pages/your-todo-list-page';
import YourToolkitResources from '@app/lang-pages/your-toolkit-resources-page';
import HowToBuildYourToolkit from '@app/lang-pages/how-to-build-your-toolkit-page';
import YourTimeline from '@app/lang-pages/your-timeline-page';
import {
    ArticlePageRedirect,
    CreateId,
    IntoSwitch,
} from '@app/components/lang-common';

const Page = () => (
    <Layout backgroundColor={theme.white}>
        {/* prettier-ignore */}
        <Router
            style={{
                minHeight: '100%',
                display: 'flex',
                flexGrow: 1,
                width: '100%',
            }}
        >
            <CategoryPage
                lang="ca_fr"
                path="/ca-fr/category/:slug/:id/"
                slug=""
                id=""
            />
            <ArticlePageRedirect
                lang="ca_fr"
                path="/ca-fr/article/:slug"
                slug=""
            />
            <ArticlePage
                lang="ca_fr"
                path="/ca-fr/article/:slug/:id"
                slug="" id=""
            />
            <LangPage
                lang="ca_fr"
                path="/ca-fr/category/:id"
            />
            <YourTodoList
                lang="ca_fr"
                path="/ca-fr/your-todos/:id"
                id=""
            />
            <YourToolkitResources
                lang="ca_fr"
                path="/ca-fr/your-toolkit/:id"
                id=""
            />
            <YourTimeline
                lang="ca_fr"
                path="/ca-fr/your-timeline/:id"
                id=""
            />
            <HowToBuildYourToolkit
                lang="ca_fr"
                path="/ca-fr/how-to-build-your-toolkit/:id"
                id=""
            />
            <WelcomePage lang="ca_fr" path="/ca-fr/welcome/:id" id="" />
            <IntoSwitch lang="ca_fr" path="/ca-fr/:id" id="" />
            <CreateId lang="ca_fr" path="/ca-fr" />
        </Router>
    </Layout>
);

export default Page;
